// @ts-ignore
import BackEndRouter from '@/general/BackEndRouter';
import AxiosInstance from '@/general/AxiosInstance';
const deleteApiKeyInit = async () => {
    document.addEventListener('modal-delete-api-key-confirmed', async (event) => {
        // @ts-ignore
        const apiKeyId = event.detail.additionalData?.apiKeyId;
        const apiUrl = BackEndRouter.generate('dashboard_delete_api_key', { apiKeyId });
        try {
            // @ts-ignore
            const response = await AxiosInstance.delete(apiUrl, { apiKeyId });
            if (response.status !== 204) {
                throw new Error('Error deleting api key');
            }
            const trElement = document.querySelector("tr[data-api-key-id='" + apiKeyId + "']");
            // @ts-ignore
            trElement.style.display = 'none';
            // @ts-ignore
            event.detail.runAfterConfirmationDone();
        }
        catch (error) {
            // @ts-ignore
            event.detail.runOnError();
        }
    });
};
export default deleteApiKeyInit;
