/*
 * App
 */
// @ts-ignore
import { Tooltip } from 'bootstrap';
import confirmationModalInit from './js/components/confirmationModal';
import copyToClipboardInit from './js/components/copyToClipboard';
import deleteApiKeyInit from '@/components/deleteApiKey';
import deleteFeedInit from '@/components/deleteFeed';
import sentryInit from './js/components/SentryInit';
import Alpine from 'alpinejs';
// @ts-ignore
import feather from 'feather-icons';
import './styles/app.scss';
require('@adminkit/core/src/js/modules/sidebar.js');
document.addEventListener('DOMContentLoaded', () => {
    sentryInit();
    Alpine.start();
    feather.replace();
    if (navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome')) {
        document.body.classList.add('safari');
    }
    console.log('DOMContentLoaded');
    // Bootstrap tooltip
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
    });
    // Copy to clipboard
    copyToClipboardInit();
    // Confirmation modal
    confirmationModalInit();
    // Modal delete api key
    deleteApiKeyInit();
    // Modal delete feed
    deleteFeedInit();
});
