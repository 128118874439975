//
// FOS JsRouting Bundle
//
//
// usage: BackEndRouter.generate('home')
//
import BackEndRouter from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources';
const routes = require('@/routes/fos_js_routes.json');
BackEndRouter.setRoutingData(routes);
BackEndRouter.setHost(window.location.hostname);
BackEndRouter.setScheme(window.location.protocol);
export default BackEndRouter;
