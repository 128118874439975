// @ts-ignore
import BackEndRouter from '@/general/BackEndRouter';
import AxiosInstance from '@/general/AxiosInstance';
const deleteFeedInit = async () => {
    document.addEventListener('modal-delete-feed-confirmed', async (event) => {
        // @ts-ignore
        const feedId = event.detail.additionalData?.feedId;
        const apiUrl = BackEndRouter.generate('dashboard_delete_feed', { feedId });
        const dashboardHomeUrl = BackEndRouter.generate('dashboard_home', { feedId });
        try {
            // @ts-ignore
            const response = await AxiosInstance.delete(apiUrl, { feedId });
            if (response.status !== 204) {
                throw new Error('Error deleting feed');
            }
            // @ts-ignore
            event.detail.runAfterConfirmationDone();
            window.location.href = dashboardHomeUrl;
        }
        catch (error) {
            // @ts-ignore
            event.detail.runOnError();
        }
    });
};
export default deleteFeedInit;
