import * as Sentry from "@sentry/browser";
const sentryInit = () => {
    const environment = document.querySelector('body')?.getAttribute('data-env') ?? 'dev';
    const release = document.querySelector('body')?.getAttribute('data-release') ?? 'unknown';
    Sentry.init({
        dsn: "https://d3dd09a0079e4225bb77d2269a6853b2@o529343.ingest.sentry.io/6084669",
        // Set the environment
        environment: environment,
        // Set the release version (based on APP_VERSION in Symfony)
        release: release,
    });
};
export default sentryInit;
