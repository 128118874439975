/**
 * copyToClipboard and set tooltip success message
 */
// @ts-ignore
import { Tooltip } from 'bootstrap';
import copyTextToClipboard from 'copy-to-clipboard';
const copyToClipboard = (elementWithTextToCopy, clickedElement) => {
    if (elementWithTextToCopy === null) {
        return;
    }
    copyTextToClipboard(elementWithTextToCopy.innerText);
    const succesMessage = clickedElement?.getAttribute('data-copy-to-clipboard-success-message');
    const defaultMessage = clickedElement?.getAttribute('data-bs-title');
    if (succesMessage !== null) {
        const tooltipInstance = Tooltip.getInstance(clickedElement);
        tooltipInstance.setContent({ '.tooltip-inner': succesMessage });
        tooltipInstance.show();
        setTimeout(() => {
            tooltipInstance.hide();
            tooltipInstance.setContent({ '.tooltip-inner': defaultMessage });
        }, 2000);
    }
};
const copyToClipboardInit = () => {
    document.querySelectorAll('[data-copy-to-clipboard]').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            const clickedElement = event.currentTarget;
            if (!(clickedElement instanceof HTMLElement)) {
                return;
            }
            if (!(clickedElement.parentNode instanceof HTMLElement)) {
                return;
            }
            const elementWithTextToCopy = clickedElement.parentNode.querySelector('[data-copy-to-clipboard-text]');
            if (!(elementWithTextToCopy instanceof HTMLElement)) {
                return;
            }
            copyToClipboard(elementWithTextToCopy, clickedElement);
        });
    });
};
export default copyToClipboardInit;
