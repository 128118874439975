// @ts-ignore
import { Modal } from 'bootstrap';
const changeModalBody = (modalId, html) => {
    const modalBodyElement = document.querySelector('#modal-' + modalId + ' .modal-body');
    if (modalBodyElement !== null) {
        modalBodyElement.innerHTML = html;
    }
};
const confirmationModalInit = () => {
    document.querySelectorAll('[data-confirmation-modal]').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            const clickedElement = event.currentTarget;
            if (!(clickedElement instanceof HTMLElement)) {
                return;
            }
            // @ts-ignore
            const modalId = clickedElement.getAttribute('data-confirmation-modal');
            // @ts-ignore
            const modalBody = clickedElement.getAttribute('data-confirmation-modal-body');
            // @ts-ignore
            const additionalData = JSON.parse(clickedElement.getAttribute('data-confirmation-modal-additional-data'));
            const modal = new Modal('#modal-' + modalId, {});
            const cancelButton = document.querySelector('#modal-' + modalId + ' [data-role="modal-cancel"]');
            const confirmButton = document.querySelector('#modal-' + modalId + ' [data-role="modal-confirm"]');
            const ajaxSpinner = document.querySelector('#modal-' + modalId + ' [role="status"]');
            // @ts-ignore
            const generalErrorMessage = document.querySelector('#modal-' + modalId).getAttribute('data-error-message');
            const runAfterConfirmationDone = () => {
                // Re-enable buttons
                cancelButton?.removeAttribute('disabled');
                confirmButton?.removeAttribute('disabled');
                // Hide ajax spinner
                // @ts-ignore
                ajaxSpinner.style.visibility = 'hidden';
                // Hide nodal
                modal.hide();
            };
            const runOnError = (errorMessage) => {
                // Re-enable buttons
                cancelButton?.removeAttribute('disabled');
                confirmButton?.removeAttribute('disabled');
                // Hide ajax spinner
                // @ts-ignore
                ajaxSpinner.style.visibility = 'hidden';
                // Add the error message to the modal body
                if (typeof errorMessage === 'undefined' || errorMessage === null || errorMessage === '') {
                    errorMessage = generalErrorMessage;
                }
                if (errorMessage === null || errorMessage === '') {
                    errorMessage = 'error';
                }
                changeModalBody(modalId, errorMessage);
            };
            // @ts-ignore
            confirmButton.addEventListener('click', (event) => {
                event.preventDefault();
                // @ts-ignore
                ajaxSpinner.style.visibility = 'visible';
                // Disable buttons
                cancelButton?.setAttribute('disabled', 'disabled');
                confirmButton?.setAttribute('disabled', 'disabled');
                // @ts-ignore
                const customEventName = 'modal-' + modalId + '-confirmed';
                // @ts-ignore
                const customEvent = new CustomEvent(customEventName, { detail: { runAfterConfirmationDone, runOnError, additionalData } });
                document.dispatchEvent(customEvent);
            });
            // Add the body content to the modal
            if (modalBody !== null) {
                changeModalBody(modalId, modalBody);
            }
            modal.show();
        });
    });
};
export default confirmationModalInit;
